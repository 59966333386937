<template>
    <div class="deckslide noselect">
        <VueLoading :active.sync="showLoadingIndicator" :can-cancel="true"></VueLoading>
        <div class="deckslide__header">
            <div class="deckslide__header__left">
                <img @click="goBack"  src="@/assets/ic_nav_back_white.svg" alt="">
            </div>
            <div class="deckslide__header__center">
                <!-- TITLE -->
            </div>
            <div class="deckslide__header__right">
                <img @click="sharePlaylist" src="@/assets/ic_nav_share_white.svg" alt="">
                <img @click="likePlaylist" v-if="playlistId && !isFavourite" src="@/assets/ic_nav_star_white.svg" alt="">
                <img @click="removePlaylistLike" v-if="playlistId && isFavourite" src="@/assets/ic_nav_star-active.svg" alt="">
                <img @click="likeUserPlaylist" v-if="userPlaylistId && !isFavourite" src="@/assets/ic_nav_star_white.svg" alt="">
                <img @click="removeUserPlaylistLike" v-if="userPlaylistId && isFavourite" src="@/assets/ic_nav_star-active.svg" alt="">
            </div>
        </div>

        <!-- COVER (FIXED WORKOUTS) -->
        <div v-if="fixedPlaylist" class="deckslide__cover">
            <h1>{{ playlistTitle }}</h1>
            <div v-html="playlistData.intro"></div>
        </div>

        <!-- CONTENT (FIXED WORKOUTS) -->
        <div v-if="fixedPlaylist" class="deckslide__content">

            <!-- META -->
            <div class="list list--workoutmeta">
                <!-- META | DAUER -->
                <div class="list__item">
                    <div class="list__item__icon">
                        <span class="list__item__icon__number">{{ duration }}'</span>
                        <img src="@/assets/ic_32_hexagon_outline_white.svg" alt="" />
                    </div>
                    <div class="list__item__content">
                        <div class="list__item__label list__item__label--white">
                            Dauer
                        </div>
                        <div class="list__item__value list__item__value--white">
                            {{ duration }} Minuten
                        </div>
                    </div>
                </div>
                <!-- META | Intensität -->
                <div class="list__item">
                    <div class="list__item__icon">
                        <img v-if="playlistData.difficulty == 3" src="@/assets/ic_32_workout_intensity-high.svg" alt="" />
                        <img v-else-if="playlistData.difficulty == 1" src="@/assets/ic_32_workout_intensity-low.svg" alt="" />
                        <img v-else src="@/assets/ic_32_workout_intensity-medium.svg" alt="" />
                    </div>
                    <div class="list__item__content">
                    <div class="list__item__label  list__item__label--white">
                        Intensität
                    </div>
                    <div v-if="playlistData.difficulty" class="list__item__value list__item__value--white">
                        {{ workoutIntensities[playlistData.difficulty-1].name }}
                    </div>
                    </div>
                </div>
                <!-- META | EQUIPMENT -->
                <div class="list__item">
                    <div class="list__item__icon">
                        <img src="@/assets/ic_32_workout_equipment.svg" alt="" />
                    </div>
                    <div class="list__item__content">
                        <div class="list__item__label  list__item__label--white">
                            Equipment
                        </div>
                        <div class="list__item__value list__item__value--white">
                            {{ playlistEquipment }}
                        </div>
                    </div>
                </div>
            </div>

<!--            <small>Enthaltene Übungen</small>-->
<!--            <div class="list list&#45;&#45;workoutexercises" >-->
<!--                <PlaylistExercisesItem v-for="(item, idx) in filteredWorkout" :key="idx" @showExercisePreview="showExercisePreview" :item="item"></PlaylistExercisesItem>-->
<!--            </div>-->
            <ModalRoute v-if="showSubModal"/>

        </div>

        <!-- COVER (FLEXIBLE WORKOUTS) -->
        <div v-if="!fixedPlaylist" class="deckslide__cover">
            <h1>{{ playlistTitle }}</h1>
            <p> Stelle Zeit, Pausen und Equipment ein und wir stellen für dich einen individuellen Übungsablauf zusammen.</p>
        </div>

        <div v-if="!fixedPlaylist" class="deckslide__time">
            <div class="deckslide__time__stepper deckslide__time--down">
                <img v-on:click="changeDuration(-1)" src="@/assets/ic_stepper_large-down.svg" alt=""/>
            </div>
            <!-- TODO @MARCO: Die Zeit soll im Format MM:SS angegeben werden -->
            <div class="deckslide__time__value">
                {{ duration }}<br><span>Minuten inkl. {{ pauseMinutes }} Min Pause</span>
            </div>
            <div class="deckslide__time__stepper deckslide__time__stepper--up">
                <img v-on:click="changeDuration(1)" src="@/assets/ic_stepper_large-up.svg" alt=""/>
            </div>
        </div>

        <div v-if="!fixedPlaylist" class="deckslide__content">

            <div class="list list--workoutmeta">
                <radio-set v-model="selectedExerciseLength" label-name="Übungsdauer (Sek.)" :options="lengthOptions"/>
                <radio-set v-model="selectedPause" label-name="Pausenlänge (Sek.)" :options="pauseOptions"/>
                <div v-if="showEquipmentSelector" class="list__item">
                    <div class="list__item__content">
                        <div class="list__item__label list__item__label--white">
                            Equipment <u @click="openEquipmentSelection" v-if="availableEquipment.length > 0">(Ändern)</u>
                        </div>
                    </div>
                    <label class="toggle">
                        <input v-model="userHasEquipment" @change="changeEquipmentSelection" type="checkbox">
                        <span class="toggle__button"></span>
                    </label>
                </div>
                <!-- Warmup -->
                <div v-if="categoryId" class="list__item">
                    <div class="list__item__content">
                        <div class="list__item__label list__item__label--white">
                            Warm-Up (3 Min.)
                        </div>
                    </div>
                    <label class="toggle">
                        <input v-model="includeWarmup" type="checkbox">
                        <span class="toggle__button"></span>
                    </label>
                </div>
            </div>

        </div>

        <PlaylistEquipment @cancelSelection="cancelEquipmentSelect" @finaliseSelection="updateEquipmentList"></PlaylistEquipment>

        <input @click="playlistStart(true)" value="Übungsvorschau ansehen" type="button" class="button button--small button--secondary">

        <div class="deckslide__button">
            <input :disabled="!dataComplete" type="button" class="button button--primary" @click="playlistStart(false)" value="Workout starten">
        </div>

        <!-- BG IMAGE -->
        <div v-if="(homePageInfo && homePageInfo.image_url)"  class="deckslide__background">
            <img :src="'https://app.sportlerplus.com/scaled/800/'+ homePageInfo.image_url" />
        </div>
    </div>
</template>
<script>
import RadioSet from "@/components/RadioSet";
import PlaylistEquipment from '@/components/PlaylistEquipment';
//import PlaylistExercisesItem from '@/components/PlaylistExercisesItem';
import {backButtonUsed, router} from '@/router';
import axios from 'axios';
import {analytics} from '@/firebase/index.js';
import { sharePage } from '@/sportlerplus-helper';
import ModalRoute from "@/components/ModalRoute.vue";
import VueLoading from 'vue-loading-overlay/dist/vue-loading';
// Import stylesheet
import 'vue-loading-overlay/src/css/index.css';

export default {
    name: 'PlaylistStart',
    components: {
        RadioSet, PlaylistEquipment, ModalRoute, VueLoading//, PlaylistExercisesItem
    },
    data() {
        return {
            dataComplete: false,
            selectedPause: null,
            selectedExerciseLength: null,
            pauseOptions: [
                { key: "10", value: 10 },
                { key: "20", value: 20 },
                { key: "30", value: 30 },
                { key: "45", value: 45 }
            ],
            lengthOptions: [
                { key: "15", value: 15 },
                { key: "30", value: 30 },
                { key: "45", value: 45 },
                { key: "60", value: 60 }
            ],
            duration: null,
            userPlaylistId: null,
            playlistId: null,
            categoryId: null,
            exercises: [],
            warmupExercises: [],
            categories: [],
            workoutSource: [],
            // only valid for playlists loaded via playlistId
            playlistData: {},
            isFavourite: false,
            userHasEquipment: false,
            includeWarmup: false,
            warmupEnd: -1,
            showEquipmentSelector: true,
            availableEquipment: [],
            fixedPlaylist: false,
            homePageInfo: null,
            showSubModal: false,
            filteredWorkout: [],
            showLoadingIndicator: false,
        }
    },
    methods: {
        init(){

            // moved to top, also found in handlePlaylistTypes(), speeds up correct display of either screen
            if (this.$route.query.playlist_id) {
                this.fixedPlaylist = true;
            }
            else {
                this.duration = 30;
                this.fixedPlaylist = false;
            }

            this.showLoadingIndicator = true;

            if (this.$route.query.duration) {
                this.duration = this.$route.query.duration;
            }
            if (this.$route.query.exerciseLength) {
                this.selectedExerciseLength = this.lengthOptions.find(el => el.value == this.$route.query.exerciseLength)
            }
            else {
                this.selectedExerciseLength = this.lengthOptions[1];
            }
            if (this.$route.query.pauseLength) {
                this.selectedPause = this.pauseOptions.find(el => el.value == this.$route.query.pauseLength);
            }
            else {
                this.selectedPause = this.pauseOptions[0];
            }

            this.$store.dispatch('fetchHomePage').then( () => {
                this.homePageInfo = this.$store.getters.getHomePageInfo;
            });

            this.categories = this.$store.getters.getCategories
            this.$store.dispatch('fetchExercises').then(() => {
                this.exercises = this.$store.getters.getExercises;
                this.warmupExercises = this.shuffle(this.exercises.filter(ex => ex.warmup));
                this.handlePlaylistTypes();
                this.dataComplete = true;
            });
            this.$store.dispatch('fetchEquipmentList');

            this.availableEquipment = this.$store.getters.getUserEquipment;
            this.userHasEquipment = this.availableEquipment.length > 0;

            // this has to be last in init
            if (backButtonUsed) {
                //console.log("detected back button, restoring user input");
                //console.log(window._lastWorkoutSettings);
                let _lastSettings = window._lastWorkoutSettings || {};
                this.selectedPause = this.pauseOptions.find(el => el.value == _lastSettings.pauseLength);
                this.selectedExerciseLength = this.lengthOptions.find(el => el.value ==_lastSettings.exerciseLength)
                this.userHasEquipment = _lastSettings.userHasEquipment;
                this.duration = _lastSettings.duration;
                this.includeWarmup = _lastSettings.includeWarmup;
            }
        },
        showExercisePreview(exercise) {
            this.showSubModal = true;
            this.$router.push("/playlist/start/preview/" + exercise.id);
        },
        filterMultipleExercises() {
            let previousWorkoutId = null;
            let numberOfExecutions = 1;
            let previousWorkout = null;
            let itemWithSquarePrev = null
            this.workoutSource.items.forEach(workout => {
                if (previousWorkoutId === null && previousWorkout === null) {
                    previousWorkout = workout;
                    previousWorkoutId = workout.id;
                }
                else if (previousWorkoutId === workout.id) {
                    numberOfExecutions++
                }
                else {
                    itemWithSquarePrev = this.exercises.find(el => el.id === previousWorkoutId);
                    if(previousWorkout.exerciseLength !== undefined) {
                        this.filteredWorkout.push({
                            numberOfExecutions: numberOfExecutions,
                            item: itemWithSquarePrev,
                            exerciseLength: previousWorkout.exerciseLength
                        });
                    }
                    else {
                        this.filteredWorkout.push({
                            numberOfExecutions: numberOfExecutions,
                            item: itemWithSquarePrev,
                            exerciseLength: this.workoutSource.exerciseLength
                        });
                    }
                    previousWorkout = workout;
                    previousWorkoutId = workout.id;
                    numberOfExecutions = 1;
                }
            });
            itemWithSquarePrev = this.exercises.find(el => el.id === previousWorkoutId);
            if (previousWorkout.exerciseLength !== undefined) {
                this.filteredWorkout.push({
                    numberOfExecutions: numberOfExecutions,
                    item: itemWithSquarePrev,
                    exerciseLength: previousWorkout.exerciseLength
                });
            } else {
                this.filteredWorkout.push({
                    numberOfExecutions: numberOfExecutions,
                    item: itemWithSquarePrev,
                    exerciseLength: this.workoutSource.exerciseLength
                });
            }
        },
        goBack() {
            if (window.history.length > 1) {
                this.$router.go(-1);
            }
            else {
                this.$router.push("/trainieren");
            }
        },
        sharePlaylist() {
            let shareUrl = this.getShareUrl();
            if (this.userPlaylistId) {
                let shareText = "Hey, ich habe gerade ein individuelles Workout in der SportlerPlus-App zusammengestellt: „{0}“.\n\nProbiere es direkt aus! 💪🚀".format(this.workoutSource.activity.name);
                sharePage(shareUrl, null, shareText, null);
            }
            else if (this.playlistId) {
                let shareText = "Hey, ich habe gerade dieses Workout in der SportlerPlus-App entdeckt: „{0}“.\n\nProbiere es direkt aus! 💪🚀".format(this.workoutSource.activity.title);
                sharePage(shareUrl, null, shareText, null);
            }
            else {
                let shareText = "Hey, ich habe gerade dieses Workout in der Kategorie „{0}“ in der SportlerPlus-App entdeckt.\n\nProbiere es direkt aus! 💪🚀".format(this.workoutSource.activity.name);
                sharePage(shareUrl, null, shareText, null);
            }
        },
        getShareUrl() {
            if (this.userPlaylistId) {
                return window.location.origin + "/sharing/?importPlaylist=" + this.userPlaylistId;
            }
            let urlParams = new URLSearchParams(window.location.search);
            if (!this.playlistId) {
                urlParams.set("duration", this.duration);
                urlParams.set("pauseLength", this.selectedPause.value);
                urlParams.set("exerciseLength", this.selectedExerciseLength.value);
            }
            let queryString = urlParams.toString();
            let shareUrl = window.location.origin + "/sharing/playlist/start?" + queryString;
            return shareUrl;
        },
        connectFitnessPlanEvent() {
            if (this.$route.query.guid) {
                this.workoutSource.fitnessPlanEvent = {
                    guid : this.$route.query.guid,
                    activityDate : this.$route.query.activityDate,
                    activityTime : this.$route.query.activityTime,
                    _realStartDate: new Date(),
                }
            }
        },
        shuffle(array) {
            let counter = array.length;

            // While there are elements in the array
            while (counter > 0) {
                // Pick a random index
                let index = Math.floor(Math.random() * counter);

                // Decrease counter by 1
                counter--;

                // And swap the last element with it
                let temp = array[counter];
                array[counter] = array[index];
                array[index] = temp;
            }

            return array;
        },
        updateEquipmentList(equipmentList) {
            this.availableEquipment = equipmentList;
            this.userHasEquipment = this.availableEquipment.length > 0;
            this.$store.dispatch('updateUserEquipment', this.availableEquipment);
        },
        cancelEquipmentSelect(equipmentList) {
            this.availableEquipment = equipmentList;
            this.userHasEquipment = this.availableEquipment.length > 0;
        },
        likePlaylist() {
            this.isFavourite = true;
            this.$store.dispatch('likePlaylist', this.playlistData);
        },
        removePlaylistLike() {
            this.isFavourite = false;
            /* removeArticleLike does not do any playlist specific stuff */
            this.$store.dispatch('removeArticleLike', this.playlistData);
        },
        likeUserPlaylist() {
            this.isFavourite = true;
            this.workoutSource.isFavourite = true;
            this.$store.dispatch('saveUserPlaylist', this.workoutSource);
        },
        removeUserPlaylistLike() {
            this.isFavourite = false;
            this.workoutSource.isFavourite = false;
            this.$store.dispatch('saveUserPlaylist', this.workoutSource);
        },
        handlePlaylistTypes() {
            let playlistId = this.$route.query.playlist_id;
            let userPlaylistId = this.$route.query.user_playlist_id;
            let categoryId = this.$route.query.category_id;

            if (playlistId) { /* public playlist */
                this.fixedPlaylist = true;
                this.playlistId = playlistId;
                analytics.logEvent('start_playlist', {
                    playlist_id: playlistId,
                });

                let apiUrl = this.$store.getters.getApiUrl;
                axios
                .get(apiUrl + 'pages/' + playlistId + "/")
                .then(response => {
                    this.playlistData = response.data;
                    let items = [];
                    let duration = 0;
                    this.playlistData.exercises.forEach( pex => {
                        let pi = this.exercises.find( i => i.id == pex.exercise.id);
                        if (pi) {
                            pi = Object.assign({}, pi)
                            pi.pauseLength = pex.pause_length;
                            pi.exerciseLength = pex.exercise_length;
                            duration += pi.pauseLength;
                            duration += pi.exerciseLength;
                            items.push(pi);
                        }
                    });
                    this.duration = Math.round(duration / 60);
                    let activity = this.playlistData;
                    this.workoutSource = { items: items, shuffle: false, title: this.playlistData.title, duration: duration / 60,
                        uid: "dummy_uid", coach: this.playlistData.coach, activity: activity, startDate: new Date() };
                    this.connectFitnessPlanEvent();
                    this.$store.dispatch('checkArticleFavourite', this.playlistId).then((res) => {
                        this.isFavourite = res;
                    });
                    this.$store.commit('addRecentTrainingActivity', this.playlistData);
                    this.filterMultipleExercises();
                    this.showLoadingIndicator = false;
                });

            }
            else if (userPlaylistId) { /* retrieve user playlist */
                this.userPlaylistId = userPlaylistId;
                analytics.logEvent('start_user_playlist', {
                    user_playlist_id: userPlaylistId,
                });
                //this.showEquipmentSelector = false;
                this.$store.dispatch('fetchUserPlaylist', userPlaylistId).then((res) => {
                    this.workoutSource = res;
                    let activity = {
                        "meta": {"type": "Custom/UserPlaylist"},
                        "name": res.name,
                        "uid": this.userPlaylistId
                    };
                    this.workoutSource.activity = activity;
                    this.workoutSource.startDate = new Date();
                    this.connectFitnessPlanEvent();
                    this.isFavourite = this.workoutSource.isFavourite;
                    this.showLoadingIndicator = false;
                });
            }
            else if (categoryId) { /* playlist from category */
                this.categoryId = categoryId;
                analytics.logEvent('start_category_playlist', {
                    category_id: categoryId,
                });
                let cat = this.categories.find(i => i.id == categoryId);
                let activity = {
                    "meta": { type: "Category"},
                    "name": cat.name,
                    "id": this.categoryId,
                    "image_url": cat.image_url,
                };
                this.workoutSource = { items:this.filterExercises(cat), shuffle: true, activity: activity, startDate: new Date() };
                this.showLoadingIndicator = false;
            }
        },
        /* minimum 5 minutes, maximum 60 minutes */
        changeDuration(val) { this.duration = Math.min(60, Math.max(5, this.duration + val)) },
        playlistStart(showPreview) {
            window._lastWorkoutSettings = {
                userHasEquipment: this.userHasEquipment,
                includeWarmup: this.includeWarmup,
                duration: this.duration,
                pauseLength: this.selectedPause.value,
                exerciseLength: this.selectedExerciseLength.value,
            }
            let options = {
                duration: this.duration,
                pauseLength: this.selectedPause.value,
                exerciseLength: this.selectedExerciseLength.value,
                share_url: this.getShareUrl(),
                completedExercises: [],
            };
            Object.assign(this.workoutSource, options);
            if (this.playlistId) {
                this.workoutSource.generatedPlaylist = this.workoutSource.items;
            }
            else {
                // user playlists in fixed order should be kept as-is
                // random playlists will keep left and right side exercises together
                let generatedPlaylist = this.generatePlaylist(this.workoutSource.shuffle);
                if (generatedPlaylist == null) { return; }
                this.workoutSource.generatedPlaylist = generatedPlaylist;
                this.workoutSource.warmupEnd = this.warmupEnd;
            }
            this.$store.dispatch('initWorkoutSource', this.workoutSource);
            if (showPreview) {
                router.push("/playlist/exercises");
            }
            else {
                router.push("/playlist/countdown/");
            }
        },
        generatePlaylist(useLeftAndRightSideEqually) {
            let duration = 0;
            let equipmentMissingCounter = 0;
            let exerciseNumber = 0;
            let itemIdx = 0;
            let videoFileList = [];
            let exercises = this.workoutSource.items;
            while ((duration + this.workoutSource.exerciseLength) <= (this.workoutSource.duration * 60)) {
                if (itemIdx % exercises.length == 0 && this.workoutSource.shuffle) {
                    exercises = this.shuffle(exercises);
                }
                let item = null;
                if (this.includeWarmup && (duration + this.workoutSource.exerciseLength) <= 180) {
                    item = this.warmupExercises[itemIdx % this.warmupExercises.length];
                }
                else {
                    item = exercises[itemIdx % exercises.length];
                    if (this.warmupEnd == -1) {
                        this.warmupEnd = exerciseNumber;
                    }
                }

                if (this.userPlaylistId == null && item.equipment && item.equipment.length > 0) {
                    console.log("equipment necessary for " + item.title);
                    let userHasAllRequiredEquipment = true;
                    item.equipment.forEach(equipmentItem => {
                        if (this.availableEquipment.find(el => el.id == equipmentItem.id) === undefined) {
                            userHasAllRequiredEquipment = false;
                        }
                    });
                    if (!userHasAllRequiredEquipment) {
                        console.log("equipment not available -- skip");
                        equipmentMissingCounter++;
                        itemIdx++;
                        // simple safety measure to avoid infinite loop if playlist contains only exercises that
                        // require equipment
                        if (equipmentMissingCounter > 100 && videoFileList.length == 0) {
                            alert("Du verfügst nicht über das nötige Equipment, um Übungen dieser Playlist auszuführen.");
                            return null;
                        }
                        continue;
                    }
                }

                if (useLeftAndRightSideEqually && ((exerciseNumber+1) == this.exerciseCount)) {
                    // Letzte Übung soll keine mit Komplementärübung sein (Zeit!)
                    if (item.complementary_exercise != null) { 
                        itemIdx++;
                        continue;
                    }
                }

                exerciseNumber++;
                itemIdx++;
                duration += this.workoutSource.exerciseLength + this.workoutSource.pauseLength;
                videoFileList.push(item);

                if (useLeftAndRightSideEqually && item.complementary_exercise) {
                    let ce_item = this.exercises.find(el => el.id == item.complementary_exercise.id)
                    if (ce_item) {
                        exerciseNumber++;
                        duration += this.workoutSource.exerciseLength + this.workoutSource.pauseLength;
                        videoFileList.push(ce_item);
                    }
                }
            }

            return videoFileList;
        },
        filterExercises(category) {
			let searchCategories = this.categories.filter(c => c.parent != null && c.parent.id == category.id).map(s => s.id);
			// add main category id
			searchCategories.push(category.id);

			let filtered = [];
			this.exercises.forEach(function(el) {
				let itemCategories = el.categories.map(ic => ic.id);
				if (itemCategories.filter(ic => searchCategories.includes(ic)).length > 0) {
					filtered.push(el);
				}
			});

			return filtered;
		},
        changeEquipmentSelection() {
            if (this.userHasEquipment) {
                this.$modal.show("playlistEquipmentModal");
            }
            else {
                this.availableEquipment = [];
            }
        },
        openEquipmentSelection() {
            this.$modal.show("playlistEquipmentModal");
        }
    },
    computed: {
        exerciseCount() {
            if (this.selectedExerciseLength == null || this.selectedPause == null) return 0;
            let exercises=Math.floor(this.duration * 60 / (this.selectedExerciseLength.value + this.selectedPause.value));
            return exercises;
        },
        workoutIntensities() {
            return this.$store.getters.getWorkoutIntensities;
        },
        pauseMinutes() {
            if (this.selectedExerciseLength == null || this.selectedPause == null) return 0;
            return Math.round(this.duration - (this.exerciseCount * this.selectedExerciseLength.value / 60));
        },
        playlistTitle() {
            if (this.categoryId) {
                let cat = this.categories.find(i => i.id == this.categoryId);
                return cat.name;
            }
            else if (this.playlistId) {
                return this.workoutSource.title;
            }
            else if (this.userPlaylistId) {
                return this.workoutSource.name;
            }
            return "Workout";
        },
        playlistEquipment() {
            if (this.workoutSource && this.workoutSource.items) {
                var equipmentSet = new Set()
                this.workoutSource.items.forEach(pi => {
                    if (pi.equipment) {
                        pi.equipment.forEach(e => equipmentSet.add(e.id));
                    }
                });
                var equipmentStringList = [];
                var equipmentList = this.$store.getters.getEquipmentList;
                equipmentSet.forEach(e => {
                    var equipmentName = equipmentList.find(el => el.id == e);
                    if (equipmentName) {
                        equipmentStringList.push(equipmentName.name)
                    }
                });
                if (equipmentStringList.length == 0) { return "Nein"; }
                return equipmentStringList.join(", ");
            }
            return "";
        }
    },
    mounted() {
        if (this.$route.query.user_playlist_id) {
            this.showEquipmentSelector = false;
        }
        this.init();
    },
    watch: {
        $route: {
            /* eslint-disable no-unused-vars */
            handler: function(newVal, oldVal) {
                if (newVal && newVal.name === "PlaylistStart") {
                    this.showSubModal = false;
                }
            }
        }
    },
}
</script>
