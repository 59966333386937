<template>
  <div>
    <label>{{labelName}}</label>
    <button :id="val.key"
      type="button"
      v-for="(val, idx) in options"
      :key="idx"
      :checked="val == value"
      :class="val == value ? 'is-active' : ''"
      @click="clicked(val)"
    >{{ val.key }}</button>
  </div>
</template>

<script>
export default {
  props: ["value", "options", "labelName"],
  methods: {
    clicked(val) {
      this.$emit("input", val);
    }
  },
};
</script>

<style lang="scss" scoped>

  div {
    display: flex;
    align-items: center;
    padding: 13px 0;

    &:not(:last-child) {
      box-shadow: inset 0 -1px 0 0 rgba($colorWhite, .16);
    }
  }

  label {
      font-size: 14px;
      line-height: 20px;
      font-weight: 600;
      letter-spacing: -0.3px;
      color: rgba($colorWhite, .48);
      margin-right: auto;
  } 

  button {
    font-size: 11px;
    font-weight: 700;
    letter-spacing: -0.23px;
    background: rgba($colorWhite, .12);
    color: $colorWhite;
    border-radius: 2px;
    padding: 6px 10px;
    transition: all .2s ease-in-out;

    &:not(:last-child) {
      margin-right: 8px;
    }

    &.is-active {
      box-shadow: inset 0 0 0 1px $colorPrimary, 0 0 0 1px $colorPrimary;
      background: rgba($colorPrimary, .24);
      color: $colorPrimary;
    }
  }

</style>