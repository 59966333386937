<template>
	<VModal name="playlistEquipmentModal" width="100%" height="auto" :shiftY="1" :scrollable="true" class="exercise-modal">
		<div class="vm-header">
			<div class="vm-header-left">
			</div>
			<div class="vm-header-center">
				Equipment
			</div>
			<div class="vm-header-right">
				<img src="@/assets/ic_nav_close.svg" alt="" @click="dismissDialog">
			</div>
		</div>
		<div class="vm-content">
			<!-- EQUIPMENT ITEMS -->
			<div>
				<label v-for="equipment in equipmentList" :key="equipment.id" class="list__item">
					<div class="list__item__content">
						<div class="list__item__label">
							{{ equipment.name }}
						</div>
					</div>
					<div class="toggle">
						<input v-model="equipment.checked" type="checkbox">
						<span class="toggle__button"></span>
					</div>
				</label>
			</div>

			<!-- SHOP STÖRER -->
			<a href="https://shop.sportlerplus.de" target="_blank" class="shop-hint">
				<img src="@/assets/shop-hint.svg" alt="Shopping Cart">
				<div class="shop-hint__content">
					<div class="shop-hint__title">
						Dir fehlt es an Equipment?
					</div>
					<div class="shop-hint__subtitle">
						Schau in unserem Shop vorbei!
					</div>
				</div>
			</a>

			<!-- BUTTON -->
			<v-btn @click="finaliseSelection" class="button button--primary">Auswahl bestätigen</v-btn>
		</div>
	</VModal>
</template>

<script>
	export default {
		name: 'PlaylistEquipment',
		data() {
			return {
				previousEquipmentList: [],
				equipmentList: [],
			}
		},
		methods: {
			init() {
				this.equipmentList = this.$store.getters.getEquipmentList;
				let availableEquipment = this.$store.getters.getUserEquipment;
				availableEquipment.forEach(ae => {
					this.equipmentList.find(eq => eq.id == ae.id).checked = true;
				});

				this.previousEquipmentList = JSON.parse(JSON.stringify(this.equipmentList));
			},
			dismissDialog() {
				this.$emit("cancelSelection", this.previousEquipmentList.filter(el => el.checked));
				this.$modal.hide('playlistEquipmentModal');
				let mainEquipmentList = this.equipmentList;
				this.previousEquipmentList.forEach(el => {
					mainEquipmentList.find(e => e.id == el.id).checked = el.checked;
				});
			},
			finaliseSelection() {
				this.previousEquipmentList = JSON.parse(JSON.stringify(this.equipmentList));
				this.$emit("finaliseSelection", this.equipmentList.filter(el => el.checked));
				this.$modal.hide('playlistEquipmentModal');
			}
		},
		mounted() {
			this.init();
		},
	}
</script>

<style lang="scss">
	.shop-hint {
		display: flex;
		align-items: center;
		padding: 12px 0 12px 16px;
		margin: 12px 0 24px;
		background: rgba($colorPrimary, .08);
		box-shadow: inset 0 0 0 1px rgba($colorPrimary, .16);
		border-radius: 8px;
		color: $colorPrimary !important;
		text-decoration: none;

		img {
			width: 42px;
			height: 48px;
			margin-right: 16px;
		}

		&__title {
			font-size: 14px;
			letter-spacing: -0.21px;
			font-weight: 700;
			margin-top: 1px;
		}

		&__subtitle {
			font-size: 14px;
			letter-spacing: -0.6px;
			font-weight: 600;
			opacity: .64;
		}
	}
</style>